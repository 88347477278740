.ontime {
  color: green;
}

.minordelay {
  color: orange;
}

.majordelay {
  color: red;
}

.actual {
  font-weight: bold;
}

.cancelled {
  text-decoration: line-through;
  color: red;
}
